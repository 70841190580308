import * as React from "react";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { StyledHomeImg } from "../StyledHome";
import img90kb from "../../images/contactImg2.png";
import img20kb from "../../images/lowtaxi.png";
import img2kb from "../../images/img2kb.png";
import {
  StyledCallBtn,
  StytledOverlay,
  StyledblurWrapper,
} from "./StyledContact";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landskrona Taxicab - Kontakta oss</title>
      </Helmet>
      <div>
        {/* <StyledblurWrapper style={{ backgroundImage: `url(${img2kb})` }}>
        </StyledblurWrapper> */}
        <StyledHomeImg src={img90kb} loading="lazy" alt="sa" />

        <StytledOverlay>
          <StyledCallBtn href="tel:+46736510694">
            <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} /> RING
            NU
            <span style={{ marginLeft: "0.5rem" }}> 073-651 06 94</span>
          </StyledCallBtn>
        </StytledOverlay>
      </div>
      {/* <StyledCallBtn href="tel:%200418332337"> <CallIcon fontSize='small'/> <span>0418-33 23 37</span></StyledCallBtn>
            <StyledCallBtn href="mailto:redalandskrona@outlook.com"> <MailIcon fontSize='small'/> <span>Skicka E-post</span></StyledCallBtn> */}
      <div
        style={{
          marginBottom: "5rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "30%",
          margin: "0 auto",
          minWidth: "18rem",
        }}
      >
        <h1>Frågor? Skicka iväg ett mail</h1>
        <p>Har du glömt något i en av våra bilar? Har du en fråga?</p>
        <StyledCallBtn href="mailto:redalandskrona@outlook.com">
          {" "}
          <MailIcon fontSize="small" style={{ marginRight: "0.5rem" }} />{" "}
          <span>Skicka E-post</span>
        </StyledCallBtn>
        <StyledCallBtn href="tel:+46736510694">
          <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} /> RING
          Förare
          <span style={{ marginLeft: "0.5rem" }}> 073-651 06 94</span>
        </StyledCallBtn>
        <StyledCallBtn
          style={{ marginBottom: "8rem" }}
          href="tel:%200418332337"
        >
          <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} /> RING
          Växeln
          <span style={{ marginLeft: "0.5rem" }}> 0418-33 23 37</span>
        </StyledCallBtn>
      </div>
    </div>
  );
};

export default Contact;
