import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledCallBtn = styled(Button)`
  && {
    background-color: #ffdd03;
    padding: 1rem;
    color: black;
    margin: 0;
    border-radius: 40px;
    span {
      color: blue;
    }
    &:hover {
      background-color: #ffdd03e0;
    }
  }
`;

// Fixa här , göra en till div som visas i mobil?
export const StytledOverlay = styled.div`
  width: 100%;
  p {
    font-weight: 500;
  }

  position: absolute;
  left: -450px;
  top: 170px;
  @media (max-width: 1200px) {
    left: -350px;
  }
  @media (max-width: 950px) {
    left: -250px;
  }
  @media (max-width: 750px) {
    left: -170px;
    top: 80px;
    h1 {
      font-size: 1.5rem;
    }
    ${StyledCallBtn} {
      left: -30px;
      top: 100px;
      padding: 0.3rem;
    }
  }
  @media (max-width: 640px) {
    left: -150px;
    top: 90px;
  }
  @media (max-width: 600px) {
    left: -130px;
    top: 90px;
  }
  @media (max-width: 500px) {
    left: -50px;
    top: 50px;
  }
`;

export const StyledblurWrapper = styled.div`
  background-size: cover;
  background-position: center;
  height: 32rem;
`;
