import {
  StyledHomeImg,
  StyledTextWrapper,
  Styledtext,
  StyledMidWrapper,
  StyledPhoneImg,
  StyledImgContainer,
  StyledMidRightContainer,
  StyledMidLeftContainer,
  StyledInfoBox,
  StyledTextDiv,
  StyledRightInnerBox,
  StyledPaymentMethods,
  StyledPaymentWrapper,
} from "./StyledHome";
import bild6ny2 from "../images/bild6ny2.png";
import tillg3 from "../images/tillg3.png";
import DevicesIcon from "@mui/icons-material/Devices";
import PaymentsIcon from "@mui/icons-material/Payments";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CallIcon from "@mui/icons-material/Call";
import { StyledCallBtn, StytledOverlay } from "./contact/StyledContact";
import { Helmet } from "react-helmet-async";


const Home = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landskrona Taxicab - Ring och boka idag</title>
      </Helmet>
      <StyledImgContainer>
        <div>
          <StyledHomeImg src={bild6ny2} alt="sa" />
          <StytledOverlay>
            <StyledCallBtn href="tel:+46736510694">
              <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} />{" "}
              RING NU
              <span style={{ marginLeft: "0.5rem" }}> 073-651 06 94</span>
            </StyledCallBtn>
          </StytledOverlay>
        </div>
      </StyledImgContainer>

      <StyledTextWrapper>
        <h1>Mer än bara en resa</h1>
        <StyledTextDiv>
          <Styledtext>
            När du stiger in i en av våra taxibilar, så är du på väg med några
            av de mest erfarna och skickliga förarna i Sverige. Våra medarbetare
            är inte bara förare, de är din vägledning till en smidig och säker
            resa.
          </Styledtext>
          <Styledtext>
            Med årtionden av branscherfarenhet och en djup förståelse för det
            lokala området så kan vi erbjuda dig den allra bästa servicen.
          </Styledtext>
        </StyledTextDiv>
      </StyledTextWrapper>

      <StyledMidWrapper>
        <StyledMidLeftContainer>
          <StyledPhoneImg src={tillg3} alt="" />
        </StyledMidLeftContainer>

        <StyledMidRightContainer>
          <StyledRightInnerBox>
            <h2>Boka taxi i mobilen</h2>
            <StyledInfoBox>
              <p>
                Vi finns alltid tillängliga när och där du behöver oss. Mobil
                och IPad-vänlig hemsida för att underlätta bokningarna.
                <br />
                <br />
              </p>
            </StyledInfoBox>
            <DevicesIcon fontSize="large" style={{ marginBottom: "1rem" }} />
          </StyledRightInnerBox>
        </StyledMidRightContainer>
      </StyledMidWrapper>

      <StyledRightInnerBox style={{}}>
        <h1>Boka taxi ska vara lätt</h1>
        <p>
          Boka taxi direkt eller boka för en specifik tidpunkt som passar dig.
        </p>
        <p>
          Det kostar inget extra och du kan ringa oss på tel.{" "}
          <StyledCallBtn
            style={{ background: "transparent" }}
            href="tel:+46736510694"
          >
            {" "}
            <CallIcon fontSize="small" /> <span>073-651 06 94</span>
          </StyledCallBtn>
        </p>
      </StyledRightInnerBox>

      <StyledPaymentWrapper>
        <h1>Betalningsmetoder</h1>
        <p>Vi accepterar flera olika typer av betalningsmetoder.</p>
        <StyledPaymentMethods>
          <div>
            <PaymentsIcon fontSize="large" />
            <h2>KONTANT</h2>
            <p>Hos oss kan du alltid betala din resa med kontanter.</p>
          </div>

          <div>
            <CheckCircleIcon fontSize="large" />
            <h2>SWISH</h2>
            <p>Betala smidigt och säkert med Swish.</p>
          </div>

          <div>
            <CreditCardIcon fontSize="large" />
            <h2>KORT</h2>
            <p>Din resa kan betalas med bankkort.</p>
          </div>
        </StyledPaymentMethods>
      </StyledPaymentWrapper>
    </div>
  );
};

export default Home;
