import styled from "styled-components";

export const StyledFooterContainer = styled.div`
    // position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    color: black;
`;
