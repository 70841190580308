import * as React from 'react';
import { StyledFooterContainer } from './StyledFooter';
import CopyrightIcon from '@mui/icons-material/Copyright';


const Footer = () => {
let today = new Date();
const currentYear = today.getFullYear();
    return(
        <StyledFooterContainer>
            <p><CopyrightIcon fontSize='small' style={{position: 'relative', bottom: '-3px'}}/> {currentYear} - Landskrona Taxi Cab </p>
        </StyledFooterContainer>
    );
}

export default Footer;