import { AppBar, Button } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import styled from "styled-components";

export const StyledLogo = styled(Button)`
    && {
        display: flex;
        flex-direction: column;
        color: black;
       
        margin-top: 0.5rem;
        p {
                color: black;
                font-size: 1rem;
                padding: 0;
                margin: 0;
                align-items: bottom;
                letter-spacing: 0.2px;
                font-weight: 700;                
        }
        em {
          

                position: relative;
                font-size: 11px;
                left: -32px;
                top: -10px;
                letter-spacing: 0.2px;
                font-weight: 100;
                color: black;
            
        }
    }
    @media (max-width: 500px) {
        em {
            font-size: 10px; 
            top: -5px;
        }
        p {
        letter-spacing: 0;
        font-size: 15px;
    }
    }
`;

export const StyledAppBar = styled(AppBar)`
&& {
     background-color: #FFDD03;
     box-shadow: none;
     .MuiContainer-root {
        padding: 0;
    }
     //position: fixed;
}
`;

export const StyledBookBtn = styled(Button)`
    && {
        color: white;
        font-weight: bold;
        background-color: black;
        height: 30px;
        margin: 1.2rem 1rem 0 1rem;
        &:hover {
            background-color: black;
        }
    }
`;

export const StyledBookBtnSmall = styled(Button)`
&& {
    color: white;
    font-weight: 700;
    background-color: black;
    font-size: 11px;
    height: 36px;
      &:hover {
            background-color: black;
        }
}
`;

export const StyledMenuIcon = styled(MenuIcon)`
   &&{

       background-color: #FFDD03;
       border-radius: 5px;
       color: black;
       padding: 0.3rem;
       margin: 0;
    }
`;

