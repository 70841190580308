import { Button } from "@mui/material";
import styled from "styled-components";

export const StyledHomeImg = styled.img`
 width: 100%;

//object-fit: contain;
/* position: relative; */
/* top: -80px !important;
z-index: -1; */
`;

export const StyledImgContainer = styled.div`

    width: 100%;
    height: 100%;


`;

export const StyledTextWrapper = styled.div`
h1 {
    text-align: center;
    font-size: 4rem;
    font-weight: 400;

    @media (max-width: 700px) {
        font-size: 3rem;
    }
    @media (max-width: 500px) {
        font-size: 2.3rem;
    }
}
    width: 100%;
    text-align: left;
    margin-bottom: 4rem;
`;

export const StyledTextDiv = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
`;

export const StyledBookBtn = styled(Button)`
    && {
        color: black;
        font-weight: bold;
        background-color: #FFDD03;
        position: inherit;

        height: 40px;
        width: fit-content;
        margin: 1.2rem 1rem 0 1rem;
        &:hover {
            background-color: #ffdd03c9;
        }
    }
`;

export const StyledImgDiv = styled.div`
position: relative; /* Set parent container to relative positioning */
  height: 100%;
  width: 100%;
  display: flex;
  @media (max-width: 900px) {
        font-size: 1.5rem;
        ${StyledBookBtn}{
            position: absolute;
            right: 5vw;
        }
    }
`;

export const Styledtext = styled.p`
    margin: 0 2rem 2rem 2rem;
    color: #545454;
    @media (max-width: 700px) {
        font-size: 1rem;
        ${StyledBookBtn}{
            position: absolute;
            right: 5vw;
        }
    }
`;

export const StyledMidWrapper = styled.div`
    width: 100%;
    display: flex;

    h2{
        font-size: 2rem;
        font-weight: 500;
    }
    @media (max-width: 900px) {
        flex-direction: column;
    }
   
`;

export const StyledPhoneImg = styled.img`
    width: 100%;
    min-height: 100%;
    flex: 1;
`;

export const StyledMidLeftContainer = styled.div`
    flex: 1;
    min-height: fit-content;
    padding: 0;
    margin: 0;
`;

export const StyledMidRightContainer = styled.div`
 @media (max-width: 900px) {
        flex-direction: column;
        width: 100%;
        margin-top: -1rem;
    }
    display: flex;
  justify-content: center; 
  align-items: center; 
    flex: 1;
    margin: 0 auto;
    align-items: center;
    background-color: #FFDD03;
    h2 {
        text-align: left;
        font-size: 3rem;
        font-weight: 400;
        @media (max-width: 500px) {
            font-size: 2.5rem;
    }
    }
`;

export const StyledRightInnerBox = styled.div`
    margin: 0 auto;
    width: 30rem;
    @media (max-width: 500px) {
            width: 100%;
        h2, p{
            padding: 1rem;
            margin-bottom: 0;
        }
    }
`;

export const StyledInfoBox = styled.div`
    display: flex;
    align-items: center;
    
    p {
        text-align: left;
        line-height: 1.5;
        width: 100%;
        font-size: 1.2rem;
        margin-bottom: 0;
    }
`;

export const StyledPaymentWrapper = styled.div`
background-color: #FFDD03;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    flex-wrap: wrap;
    h1 {
        margin-bottom: 0;
    }
`;

export const StyledPaymentMethods = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-top: 3em;
    @media (max-width: 900px) {
     
        justify-content: center;
        margin-top: 2em;
    }

    div{
        width: 18rem;
        @media (max-width: 900px) {
            width: 12rem;
    }
    @media (max-width: 600px) {
            width: 7rem;
            font-size: 0.7rem;
    }
    }
`;

export const StyledCallBtn = styled(Button)`
&&{
    background-color: transparent;
    color: black;
    padding: 0;
    margin: 0;

    span {
        color: blue;
    }
}
`;