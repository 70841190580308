import * as React from "react";
import CallIcon from "@mui/icons-material/Call";
import { StyledHomeImg } from "../StyledHome";
import img90kb from "../../images/contactImg2.png";
import { StyledCallBtn, StytledOverlay } from "../contact/StyledContact";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  function formatLocations(locations) {
    const isPhone = window.innerWidth < 600;
    const maxLength = isPhone ? 40 : 90;
    const formattedLocations = [];

    locations.forEach((location) => {
      const { location: loc, price } = location;
      const remainingSpaces =
        maxLength - (loc.length + price.toString().length);

      if (remainingSpaces > 0) {
        const separator = "_".repeat(remainingSpaces);
        const formattedLine = `${loc}${separator}${price} kr`;
        formattedLocations.push(formattedLine);
      } else {
        // Handle case where location and price exceed maxLength
        const formattedLine = `${loc.slice(0, maxLength - 4)}...${price} kr`;
        formattedLocations.push(formattedLine);
      }
    });
    return formattedLocations;
  }

  // Usage:
  const locations = [
    { location: " Kastrup ", price: 1600 },
    { location: " Ängelholm flygplats ", price: 860 },
    { location: "Sturup flygplats ", price: 900 },
    { location: " Helsingborg ", price: 550 },
    { location: "  Malmö ", price: 800 },
    { location: " Lund ", price: 650 },
  ];
  const formatted = formatLocations(locations);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Landskrona Taxicab - Priser</title>
      </Helmet>
      <div>
        <StyledHomeImg src={img90kb} loading="lazy" alt="sa" />
        <StytledOverlay>
          <StyledCallBtn href="tel:+46736510694">
            <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} />
            RING NU
            <span style={{ marginLeft: "0.5rem" }}> 073-651 06 94</span>
          </StyledCallBtn>
        </StytledOverlay>
      </div>
      <div
        style={{
          marginBottom: "3rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          width: "100%",
          margin: "0 auto",
          minWidth: "18rem",
        }}
      >
        <h1>Fasta priser</h1>
        <p style={{ marginBottom: 0 }}>
          Hos oss behöver du inte oroa dig för att priset ska stiga under din
          färd. Vi erbjuder fasta priser till ett gäng utvalda resmål.
        </p>
        <span style={{ fontSize: "12px", padding: 0, margin: 0 }}>
          (Gäller endast vardagar)
        </span>
      </div>
      <div
        style={{
          marginTop: "1rem",
          marginBottom: "8rem",
          background: "#FFDD03",
          padding: "1rem",
        }}
      >
        {formatted.map((price) => (
          <p>{price}</p>
        ))}
      </div>
      <StyledCallBtn href="tel:+46736510694">
        <CallIcon fontSize="small" style={{ marginRight: "0.5rem" }} />
        RING
        <span style={{ marginLeft: "0.5rem" }}> 073-651 06 94</span>
      </StyledCallBtn>
    </div>
  );
};

export default Contact;
