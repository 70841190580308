import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Drawer, List, ListItem } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  StyledLogo,
  StyledAppBar,
  StyledBookBtn,
  StyledMenuIcon,
  StyledBookBtnSmall,
} from "./StyledAppbar";
import { useNavigate } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";

const pages = [
  { label: "Kontakta oss", path: "/kontakt", icon: "CallIcon" },
  { label: "Priser", path: "/priser", icon: "LocalOfferIcon" },
];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [state, setState] = useState({ left: false });
  const navigate = useNavigate();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleOpenNavMenu = (linkPath) => {
    console.log(linkPath, "path?");
    navigate(linkPath);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handlebooking = (e, from) => {
    e.preventDefault();
    if (from === "phone") {
      window.location.href = "tel:+46736510694";
    } else {
      navigate("/kontakt");
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pages.map((page) => (
          <ListItem
            key={page.path}
            disablePadding
            style={{ padding: "0.5rem" }}
          >
            {renderIcon(page.icon)}
            <Button
              style={{ fontWeight: "700" }}
              key={page.path}
              onClick={() => handleOpenNavMenu(page.path)}
              onClose={handleCloseNavMenu}
              sx={{ my: 1, color: "black", display: "block" }}
            >
              {page.label}
            </Button>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  function renderIcon(iconName) {
    switch (iconName) {
      case "CallIcon":
        return <CallIcon />;
      case "LocalOfferIcon":
        return <LocalOfferIcon />;
      // Add cases for other icons as needed
      default:
        return null;
    }
  }

  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <StyledLogo onClick={() => handleOpenNavMenu("/")}>
              <p>Landskrona Taxi Cab</p>
              <em>Mer än bara en resa</em>
            </StyledLogo>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-haspopup="true"
              onClick={toggleDrawer("left", true)}
              color="inherit"
            >
              <StyledMenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <StyledLogo onClick={() => handleOpenNavMenu("/")}>
              <p>Landskrona Taxi Cab</p>
              <em>Mer än bara en resa</em>
            </StyledLogo>
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              width: "4rem",
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <StyledBookBtnSmall onClick={(e) => handlebooking(e, "phone")}>
              Ring taxi
            </StyledBookBtnSmall>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <StyledBookBtn onClick={(e) => handlebooking(e, "web")}>
              Ring taxi
            </StyledBookBtn>
            {pages.map((page) => (
              <Button
                style={{ fontWeight: "700" }}
                key={page.path}
                onClick={() => handleOpenNavMenu(page.path)}
                onClose={handleCloseNavMenu}
                sx={{ my: 2, color: "black", display: "block" }}
              >
                {page.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </Container>
      <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </StyledAppBar>
  );
};

export default NavBar;
